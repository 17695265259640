<template>
  <page>
    <template slot="headerRight">
      <el-button
        v-auth="`${isMiddleMan ? 'middleman:sys:user:save' : 'sys:user:saver'}`"
        @click="
          addVisible = true
          currentItem = null
        "
        type="primary"
      >
        添加用户
      </el-button>
      <!-- <el-button @click="delHandler(false)" :disabled="!multipleSelection.length" type="danger"> 批量删除 </el-button> -->
    </template>

    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="用户名称" prop="userName">
          <el-input v-model="params.userName" placeholder="用户名称"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="roleName">
          <el-input v-model="params.roleName" placeholder="用户名称"></el-input>
        </el-form-item>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        height="100%"
      >
        <!-- <el-table-column  type="selection" width="55" /> -->
        <el-table-column
          show-overflow-tooltip
          prop="username"
          label="用户名称"
        />
        <el-table-column show-overflow-tooltip prop="roleNames" label="角色" />
        <el-table-column show-overflow-tooltip prop="mobile" label="登录账号" />
        <el-table-column show-overflow-tooltip label="状态">
          <template slot-scope="{ row }">
            <span :style="{ color: row.status == 1 ? 'green' : 'red' }">{{
              row.status == 1 ? '启用' : '冻结'
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="createTime"
          label="创建时间"
        />
        <el-table-column width="220" label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-auth="
                `${
                  isMiddleMan
                    ? 'middleman:sys:user:resetPwd'
                    : 'sys:user:resetPwd'
                }`
              "
              type="text"
              @click="resetPassword(row.mobile)"
              >重置密码</el-button
            >
            <el-button
              v-auth="
                `${
                  isMiddleMan ? 'middleman:sys:user:modify' : 'sys:user:modify'
                }`
              "
              @click="editHandler(row)"
              type="text"
              size="mini"
              >编辑</el-button
            >
            <el-button
              v-auth="
                `${
                  isMiddleMan ? 'middleman:sys:user:delete' : 'sys:user:delete'
                }`
              "
              @click="delHandler(row.userId)"
              type="text"
              size="mini"
              >删除</el-button
            >
            <el-button
              v-auth="
                `${
                  isMiddleMan
                    ? row.status == 1
                      ? 'middleman:sys:user:end'
                      : 'middleman:sys:user:open'
                    : row.status == 1
                    ? 'sys:user:end'
                    : 'sys:user:open'
                }`
              "
              @click="operate(row)"
              type="text"
              >{{ row.status == 1 ? '冻结' : '启用' }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <AddModal
      v-if="addVisible"
      @getData="getData(true)"
      :visible.sync="addVisible"
      :roleList="roleList"
      :current-item="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddModal from './components/AddModal.vue'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      visible: false,
      addVisible: false,
      currentItem: null,
      tableData: [],
      memberList: [],
      multipleSelection: [],
      roleList: [],
      params: {
        limit: 20,
        page: 1,
        userName: '',
        roleName: ''
      },
      totalCount: 0
    }
  },
  created() {
    this.getData()
  },
  computed: {
    isMiddleMan() {
      return localStorage.getItem('userType') == 'MIDDLEMAN'
    }
  },
  components: {
    AddModal
  },
  methods: {
    async resetPassword(mobile) {
      try {
        await this.$confirm(
          `默认密码是12345678,请确认是否要重置密码?`,
          '提示',
          {
            type: 'warning',
            confirmButtonText: '确认',
            cancelButtonText: '取消'
          }
        )

        try {
          this.loading = true

          await this.$api.memberManage.resetPassword({ mobile })
          this.$message.success('操作成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },
    async operate(row) {
      try {
        await this.$confirm(
          `是否要${row.status == 1 ? '停用' : '启用'}?`,
          '提示',
          {
            type: 'warning',
            confirmButtonText: '确认',
            cancelButtonText: '取消'
          }
        )

        try {
          this.loading = true

          await this.$api.memberManage.modifyUser({
            userId: row.userId,
            username: row.username,
            userRoleIdList: (row.roleIds || '').split(',').map(Number),
            status: row.status == 1 ? 0 : 1
          })

          this.$message.success('操作成功！')
          this.getData(true)
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {}
    },
    async getData(query) {
      try {
        this.loading = true

        let params = { ...this.params }

        if (query) {
          params.limit = 20
          params.page = 1
        }

        const res = await this.$api.memberManage.getMemberList(params)
        if (res.page && res.page.list) {
          this.tableData = res.page.list
        }

        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => _.id)
    },

    async delHandler(userId) {
      try {
        await this.$confirm(
          userId ? '是否要删除该用户？' : '是否要批量删除所选用户？',
          '温馨提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )

        try {
          this.loading = true

          await this.$api.memberManage.deleteUser({
            userId
          })
          this.$message.success('删除成功')
          this.getData()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {
        console.log(err)
      }
    },
    async editHandler(row) {
      this.currentItem = row
      this.addVisible = true
    }
  }
}
</script>

<style scoped lang="scss"></style>
