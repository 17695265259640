<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="!isCreated ? '编辑' : '新增'"
    :visible.sync="show"
    width="500px"
  >
    <div class="content">
      <el-form
        label-width="100px"
        :model="form"
        size="mini"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="用户名称" prop="username">
          <el-input
            @input="val => (form.username = forbidSpecial(val))"
            maxlength="20"
            v-model="form.username"
            placeholder="请输入用户名称"
          />
        </el-form-item>
        <el-form-item v-if="isCreated" label="登录账号" prop="mobile">
          <el-input
            type="number"
            v-model="form.mobile"
            placeholder="登录账号由手机号组成"
          />
        </el-form-item>
        <el-form-item v-if="isCreated" label="密码" prop="password">
          <el-input
            type="password"
            show-password
            v-model="form.password"
            placeholder="请输入密码"
          />
        </el-form-item>
        <el-form-item label="角色" prop="userRoleIdList">
          <el-select clearable multiple v-model="form.userRoleIdList">
            <el-option
              v-for="item in roleList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" :loading="loading" @click="submit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      rules: {
        username: [{ required: true, message: '请输入用户名称' }],
        mobile: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) return callback(new Error('请输入登录账号'))

              if (!/^1[\d | *]{10}$/.test(value)) {
                return callback(new Error('手机号不正确'))
              }
              callback()
            }
          }
        ],
        userRoleIdList: [
          { required: true, message: '请输入选择角色', trigger: 'blur' }
        ]
      },
      roleList: [],
      form: {
        username: '',
        mobile: '',
        userRoleIdList: []
      },
      loading: false
    }
  },
  async created() {
    await this.getRoleList()
    if (this.currentItem) {
      this.form.username = this.currentItem.username
      this.form.mobile = this.currentItem.mobile
      this.form.userRoleIdList = (this.currentItem.roleIds || '')
        .split(',')
        .map(Number)
    }
  },
  methods: {
    async getRoleList() {
      try {
        const res = await this.$api.common.getRoleAllList()

        this.roleList = (res.list || []).map(item => ({
          label: item.roleName,
          value: item.roleId
        }))
      } catch (e) {
        console.log(e)
      }
    },
    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.loading = true
          try {
            const params = { ...this.form }

            if (!this.isCreated) {
              params.userId = this.currentItem.userId
              await this.$api.memberManage.modifyUser(params)
              this.$message.success('编辑成功')
            } else {
              await this.$api.memberManage.saveUser(params)
              this.$message.success('添加成功')
            }
            this.show = false
            this.$emit('getData')
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-select__tags {
  max-width: 340px !important;
}
</style>
